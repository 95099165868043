@import 'constants';
@import 'shared-styles';

@mixin insights-table() {
  display: flex;
  flex-direction: column;

  & mat-header-row {
    min-height: 2.4rem;
    background: $color-white;
  }

  & mat-row {
    z-index: 99; // mat-header-row is on z-index: 100. We want to make sure that a mat-row never overlaps.
    border-bottom: 0.1rem solid $color-blue-gray-100;
    max-height: 4.8rem;
    min-height: 4.8rem;
  }

  & mat-header-row,
  mat-row {
    display: flex;
    justify-content: space-between;
  }

  & mat-footer-row {
    flex: 1;
  }

  & mat-header-cell {
    border-bottom: 1px solid transparent;
    font: $font-caption;
    color: $font_color-light;
    line-height: 1.3;

    span {
      @include ellipse;
    }

    &:not(.insights-table_header-cell--left-aligned) .mat-sort-header-container {
      padding-left: 1.5rem;
    }

    & .mat-sort-header-container,
    .mat-sort-header-content {
      min-width: 0;
    }

    // disable default styles around the sort arrow
    & .mat-sort-header-arrow {
      color: $color-blue-gray-900;
      display: none;

      .mat-sort-header-stem {
        display: none;
      }

      .mat-sort-header-indicator {
        opacity: 1;
        /* stylelint-disable-next-line color-named */
        color: black;
        font-weight: bold;

        // Hide default arrow as its composed of left, right and middle
        .mat-sort-header-pointer-left,
        .mat-sort-header-pointer-right,
        .mat-sort-header-pointer-middle {
          display: none;
        }
      }

      &.mat-sort-header-disabled .mat-sort-header-container {
        cursor: unset;
      }
    }

    // hover style for sort header
    &:not(.mat-sort-header-disabled) .mat-sort-header-container {
      flex: 1;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;

      &:hover {
        background-color: rgba($color-blue-gray-800, $reduced-opacity-008);
        cursor: pointer;
      }
    }

    &:not(.mat-sort-header-disabled):not(.insights-table_header-cell--left-aligned) .mat-sort-header-container {
      justify-content: center;
    }

    mat-icon {
      min-width: 1.2rem;
    }

    & > * {
      padding: 0 0.8rem;
    }
  }

  & mat-cell {
    & > span {
      @include ellipse;
    }

    & > * {
      margin: 0 0.8rem;
    }
  }

  & mat-header-cell,
  mat-cell {
    flex: 1;
    justify-content: center;

    display: flex;
    padding: 0;
  }

  // skeleton
  & .insights-table_skeleton {
    margin-right: 0.5rem;
    flex: 1;
    height: 1.8rem;
    border-radius: 0.3rem;

    @include skeletonLoader;
  }

  &.insights-table_styled-header mat-header-cell {
    border-top: 1px solid $color-blue-gray-100;
    border-bottom: 1px solid $color-blue-gray-100;
  }
}
