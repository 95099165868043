/* stylelint-disable */
@use 'sass:list';
@import 'insights-table';

$colorsHsl:
  [ [ 'white',
  0,
  0,
  100],
  [ 'success',
  140,
  54,
  47],
  [ 'warning',
  28,
  100,
  52],
  [ 'error',
  0,
  100,
  62],
  [ 'chart-label',
  200,
  19,
  18,
  0.56],
  [ 'chart-lines',
  198,
  16,
  84,
  0.5],
  [ 'average-line',
  200,
  16,
  62],
  [ 'color1',
  258,
  77,
  60],
  [ 'color2',
  191,
  66,
  49],
  [ 'color3',
  208,
  68,
  49],
  [ 'color4',
  20,
  91,
  53],
  [ 'color5',
  340,
  76,
  55]];

:root {
  @each $color-parts in $colorsHsl {
    $color-name: list.nth($color-parts, 1);
    --ins-#{$color-name}-h: #{list.nth($color-parts, 2)};
    --ins-#{$color-name}-s: #{list.nth($color-parts, 3)}%;
    --ins-#{$color-name}-l: #{list.nth($color-parts, 4)}%;
    --ins-#{$color-name}-a: #{if(length($color-parts) == 5, list.nth($color-parts, 5), 1)};
    --ins-#{$color-name}: hsla(var(--ins-#{$color-name}-h), var(--ins-#{$color-name}-s), var(--ins-#{$color-name}-l), var(--ins-#{$color-name}-a));
  }
}

// added mat-table to increase specificity
mat-table.insights-table {
  @include insights-table();
}
