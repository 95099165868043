:root {
  .mat-mdc-menu-item:hover:not([disabled]),
  .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
  .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-mdc-menu-item-highlighted:not([disabled]) {
    background: rgba(38, 50, 56, 0.08);
  }

  .mat-mdc-menu-item {
    min-height: 0;

    .mdc-list-item__primary-text {
      width: 100%;
    }
  }
}
