.mat-mdc-dialog-container {
  --mdc-dialog-container-elevation: 0;
}

.cdk-overlay-pane.fullscreen {
  --mdc-dialog-container-shape: 0; // remove border radius on fullscreen dialogs
  max-width: unset !important;

  .mat-mdc-dialog-container {
    max-height: unset;
  }
}

.mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: 0rem;
  position: initial !important; // remove position: relative as we use a lot of position: absolute in our dialogs
}
