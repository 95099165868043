@import (reference, optional) '../../constants';

.mat-mdc-chip {
  --mdc-chip-elevated-container-color: @color-blue-gray-050;
  --mdc-chip-elevated-disabled-container-color: @color-blue-gray-050;
  --mdc-chip-label-text-color: @color-blue-gray-900;
  --mdc-chip-disabled-label-text-color: @color-blue-gray-900;
  --mdc-chip-with-icon-icon-color: @color-blue-gray-900;
  --mdc-chip-with-icon-disabled-icon-color: @color-blue-gray-900;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: @color-blue-gray-900;
  --mdc-chip-with-trailing-icon-trailing-icon-color: @color-blue-gray-900;
  --mdc-chip-with-icon-selected-icon-color: @color-blue-gray-900;

  --mdc-chip-container-shape-radius: 0.4rem;
  cursor: pointer;
  border-radius: 0.4rem;

  &:hover {
    background-color: @color-blue-gray-100;
  }

  mat-icon.celum-chip_action-icon,
  celum-icon.celum-chip_action-icon mat-icon {
    margin-left: 0.7rem;

    &:hover {
      border-radius: 0.4rem;
      background-color: rgba(@color-blue-gray-800, 0.08);
      cursor: pointer;
    }
  }
}

mat-chip.mat-mdc-standard-chip .mdc-evolution-chip__action--presentational {
  cursor: pointer;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
