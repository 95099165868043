@import 'constants';

.celum-expansion-panel {
  background-color: unset;

  .mat-expansion-panel-header {
    height: 4rem;
    margin: 0 0.5rem;
    padding: 0 0 0 1.6rem;
    background-color: unset;

    font: $font-body;
    color: $color-blue-gray-900;
    font-weight: 600;

    border-bottom: $color-blue-gray-100;
    border-width: 1px;
    border-style: hidden hidden solid hidden;
    border-radius: 0.3rem 0.3rem 0 0;
  }

  .mat-expansion-panel-header:hover,
  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: rgba($color-blue-gray-800, $reduced-opacity-008);
    transition: 100ms;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 4rem;
  }

  .mat-expansion-panel-body {
    //FIXME: remove hardcoded top margin or make it configurable
    margin-top: 0.7rem;
    padding: 0;
  }
}
