@import 'portal-constants';
@import 'shared-styles';

@layer portal-components-layer {
  button.por-btn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &:disabled {
      cursor: default;

      background-color: $color-gray-3;
      color: $color-gray-5;
    }

    &:not([disabled]) {
      &:not(.por-btn--light-actions) {
        @include applyHover(var(--por-button-hover-color, $color-gray-6), 0.08);

        &:active {
          @include addLayer(var(--por-button-active-color, $color-gray-6), 0.12);
        }
      }

      &.por-btn--light-actions {
        @include applyHover($color-white, 0.08);

        &:active {
          @include addLayer($color-white, 0.12);
        }
      }
    }
  }
}
