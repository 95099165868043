@import (reference, optional) 'constants';
@import 'shared-styles';

// available custom properties:
// --clm-native-scrollbar-thumb-color
// --clm-native-scrollbar-thumb-hover-color
// --clm-native-scrollbar-track-color
// --clm-native-scrollbar-track-hover-color
// --clm-native-scrollbar-track-border-radius

// Firefox is not supporting webkit-scrollbar styles, so we have to fallback to a firefox-specific subset of styling options
// It's not 100% the same as on chrome but still very similar and more than good enough for us
@-moz-document url-prefix() {
  .celum-scrollbar,
  .celum-scrollbar--dark {
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .celum-scrollbar {
    scrollbar-color: var(--clm-native-scrollbar-thumb-color, fade(@color-blue-gray-400, 50)) var(--clm-native-scrollbar-track-color, @color-blue-gray-050);
  }

  .celum-scrollbar--dark {
    scrollbar-color: fade(white, 50) fade(black, 30);
  }

  .celum-scrollbar-child {
    margin-right: unset;
  }
}

// moz-appearance is only supported by firefox, so we can put styles in here in case any other major browser is used (they support webkit-scrollbar)
@supports not (-moz-appearance: none) {
  .celum-scrollbar:not(perfect-scrollbar),
  .celum-scrollbar--dark:not(perfect-scrollbar) {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.8rem;
      height: 0.8rem;
      background-color: var(--clm-native-scrollbar-track-color, transparent);
      transition: background-color 0.2s ease;
      border-radius: var(--clm-native-scrollbar-track-border-radius, 0.8rem);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
    }

    &::-webkit-scrollbar-corner {
      background: transparent; // if both horizontal and vertical scrollbars are active in dark mode, corner would be white
    }
  }

  .celum-scrollbar {
    &::-webkit-scrollbar:hover {
      background-color: var(--clm-native-scrollbar-track-hover-color, @color-blue-gray-050);
    }

    .thumb-color(
            var(--clm-native-scrollbar-thumb-color, fade(@color-blue-gray-400, 50)),
            var(--clm-native-scrollbar-thumb-hover-color, fade(@color-blue-gray-400, 70))
    );
  }

  .celum-scrollbar--dark {
    &::-webkit-scrollbar:not(perfect-scrollbar) {
      background-color: transparent;
    }

    &::-webkit-scrollbar:hover {
      background-color: fade(black, 30);
    }

    .thumb-color(fade(white, 50), fade(white, 70));
  }
}

.thumb-color(@color, @hoverColor) {
  &::-webkit-scrollbar-thumb {
    background: @color;

    &:horizontal {
      background: @color;
    }

    &:hover {
      background: @hoverColor;

      &:horizontal {
        background: @hoverColor;
      }
    }
  }
}
