// KEEP IN SYNC WITH constants.less!!
/*
* --------------------------------------------------------
* Default Celum color palette
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8ffcc753e759710a4
* --------------------------------------------------------
*/

$color-blue-gray-900: #263238;
$color-blue-gray-800: #37474f;
$color-blue-gray-700: #455a64;
$color-blue-gray-600: #546e7a;
$color-blue-gray-500: #607d8b;
$color-blue-gray-400: #78909c;
$color-blue-gray-300: #90a4ae;
$color-blue-gray-200: #b0bec5;
$color-blue-gray-100: #cfd8dc;
$color-blue-gray-050: #eceff1;

$color-white: #ffffff;
$color-black: $color-blue-gray-900;
$color-red: #ff3f3f;
$color-purple: #ab47bc;
$color-violet: #794be8;
$color-pink: #ec407a;
$color-orange: #ff7d09;
$color-yellow: #ffca28;
$color-cyan-green: #32c5bf;
$color-cyan-blue: #26c6da;
$color-green: #37ba63;
$color-light-green: #47cc8a;
$color-deep-blue: #4466b4;
$color-blue: #2298ff;
$color-gray: #97aab3;

$color-primary: var(--colorPrimary);
$color-secondary: #56c4cf;
$color-tertiary: #005682;
$color-quaternary: #62cae1;
$color-accent: $color-blue-gray-300;

$color-success: $color-green;
$color-warning: $color-orange;
$color-error: $color-red;
/*
* --------------------------------------------------------
* Typography standards
* $link: https://app.zeplin.io/project/5be9276a92d01e7ea2130210/screen/5be927c8f9d5b53eec2be840
* --------------------------------------------------------
*/
$font-family: 'Open Sans', Arial, sans-serif;

$font-display: normal 300 4rem/1.05 $font-family;
$font-headline: normal 300 2.4rem/1.33 $font-family;
$font-title: normal 400 2rem/1.4 $font-family;
$font-subheader: normal 400 1.6rem/1.75 $font-family;
$font-body: normal 400 1.3rem/1.38 $font-family;
$font-caption: normal 400 1.1rem/1.1 $font-family;
$font-button: normal 300 1.2rem/1.83 $font-family;

// Font color palette
$font_color-black: $color-black;
$font_color-medium: rgba($color-black, 0.75);
$font_color-light: rgba($color-black, 0.56);
$font_color-disabled: rgba($color-black, 0.42);
$font_color-blue-gray: $color-blue-gray-600;

/*
* --------------------------------------------------------
* Screen breakpoints
* --------------------------------------------------------
*/
$mobile: 320px;
$phablet: 600px;
$tablet: 1024px;
$desktop: 1920px;

/*
* --------------------------------------------------------
* Element reusable colors like borders, dividers etc
* --------------------------------------------------------
*/

// will be added more later...
$color-divider: $color-blue-gray-050;

/*
* --------------------------------------------------------
* Shadows standard
* --------------------------------------------------------
*/
$box-shadow-1dp:
  0px 1px 3px 0px rgba($color-black, 0.2),
  0px 2px 1px -1px rgba($color-black, 0.12),
  0px 1px 1px 0 rgba($color-black, 0.14);
$box-shadow-2dp:
  0 1px 5px 0 rgba($color-black, 0.2),
  0 3px 1px -2px rgba($color-black, 0.12),
  0 2px 2px 0 rgba($color-black, 0.14);
$box-shadow-3dp:
  0 1px 8px 0 rgba($color-black, 0.2),
  0 3px 3px -2px rgba($color-black, 0.12),
  0 3px 4px 0 rgba($color-black, 0.14);
$box-shadow-4dp:
  0 2px 4px -1px rgba($color-black, 0.2),
  0 1px 10px 0 rgba($color-black, 0.12),
  0 4px 5px 0 rgba($color-black, 0.14);
$box-shadow-6dp:
  0 3px 5px -1px rgba($color-black, 0.2),
  0 1px 18px 0 rgba($color-black, 0.12),
  0 6px 10px 0 rgba($color-black, 0.14);
$box-shadow-8dp:
  0 5px 5px -3px rgba($color-black, 0.2),
  0 3px 14px 2px rgba($color-black, 0.12),
  0 8px 10px 1px rgba($color-black, 0.14);
$box-shadow-9dp:
  0 5px 6px -3px rgba($color-black, 0.2),
  0 3px 16px 2px rgba($color-black, 0.12),
  0 9px 12px 1px rgba($color-black, 0.14);
$box-shadow-12dp:
  0 7px 8px -4px rgba($color-black, 0.2),
  0 5px 22px 4px rgba($color-black, 0.12),
  0 12px 17px 2px rgba($color-black, 0.14);
$box-shadow-16dp:
  0 8px 10px -5px rgba($color-black, 0.2),
  0 6px 30px 5px rgba($color-black, 0.12),
  0 16px 24px 2px rgba($color-black, 0.14);
$box-shadow-24dp:
  0 11px 15px -7px rgba($color-black, 0.2),
  0 9px 46px 8px rgba($color-black, 0.12),
  0 24px 38px 3px rgba($color-black, 0.14);

/*
* --------------------------------------------------------
* ... Next are in progress ...
* --------------------------------------------------------
*/

$reduced-opacity-008: 0.08;
$reduced-opacity-012: 0.12;
$reduced-opacity-040: 0.4;
$reduced-opacity-050: 0.5;
$reduced-opacity-056: 0.56;
$reduced-opacity: 0.8;

/**
* usage: @media #{\$smartphone-screens}
*/
$smartphone-screens: '(max-width: ' ($phablet - 1px) ')';
$tablet-screens: '(min-width: ' ($phablet) ') and (max-width: ' $tablet ')';
$small-screens: '(max-width: ' $tablet ')';
$medium-screens: '(min-width: ' ($tablet + 1) ') and (max-width: ' $desktop ')';
$medium-and-larger-screens: '(min-width: ' ($tablet + 1) ')';
$medium-screens-sidebar-small: '(min-width: ' ($tablet + 1) ') and (max-width: 1279px)';
$phablet-landscape-screens: '(min-width: ' $phablet ') and (max-width: ' $tablet ') and (max-height: 500px)';

$isIE: '(-ms-high-contrast: none), (-ms-high-contrast: active)';
$isSafari: '(min-resolution: .001dpcm)';

$sidebar-width: 38rem;
$sidebar-small-width: 28rem;
$sidebar-tab-group: 40rem;

$header-shrink-time: 0.5s;
$header-shrink-time-mobile: 0.2s;

$header-expand-time: 0.5s;

$content-panel-height: 6.4rem;
$content-panel-height-mobile: 4rem;

$content-expanding-transition: top $header-shrink-time ease-out;
$content-shrinking-transition: top $header-expand-time ease-out;
$content-sidebar-transition: right 0.5s ease-out;

$system-bar-width: 4rem;

$magic-button-width: 12rem;
