@import (reference, optional) '../../constants';

:root {
  mat-checkbox.mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 3.2rem;
    --mdc-checkbox-checkbox-size: 1.4rem;
    --mdc-checkbox-selected-icon-color: @color-primary !important;
    --mdc-checkbox-selected-checkmark-color: @color-white !important;
    --mdc-checkbox-selected-hover-icon-color: @color-primary !important;
    --mdc-checkbox-selected-focus-icon-color: @color-primary !important;
    --mdc-checkbox-selected-pressed-icon-color: @color-primary !important;

    --mdc-checkbox-disabled-selected-icon-color: @color-blue-gray-300 !important;
    --mdc-checkbox-disabled-unselected-icon-color: rgba(@color-blue-gray-300, 0.36) !important;
    --mdc-checkbox-unselected-icon-color: @color-blue-gray-300 !important;
    --mdc-checkbox-unselected-focus-icon-color: @color-blue-gray-300 !important;
    --mdc-checkbox-unselected-hover-icon-color: @color-blue-gray-300 !important;
    --mdc-checkbox-unselected-pressed-icon-color: @color-blue-gray-300 !important;

    &.mat-primary label {
      color: @color-primary;
    }

    label {
      padding-left: 0.9rem;
    }

    .mat-mdc-checkbox-touch-target {
      width: var(--mdc-checkbox-state-layer-size);
      height: var(--mdc-checkbox-state-layer-size);
    }

    &-ripple,
    .mdc-checkbox__ripple {
      width: var(--mdc-checkbox-state-layer-size) !important;
      height: var(--mdc-checkbox-state-layer-size) !important;
      transform: translate(
        calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-checkbox-size)) / -2),
        calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-checkbox-size)) / -2)
      ) !important;

      .mat-ripple-element {
        background-color: @color-blue-gray-900 !important;
        opacity: 0.16;
      }
    }

    .mdc-checkbox__ripple {
      background-color: @color-blue-gray-900 !important;
    }

    &-checked {
      .mat-mdc-checkbox-ripple .mat-ripple-element,
      .mdc-checkbox__ripple {
        background-color: @color-primary !important;
      }
    }

    &-disabled {
      opacity: 0.36;
    }

    .mdc-checkbox {
      width: var(--mdc-checkbox-checkbox-size);
      height: var(--mdc-checkbox-checkbox-size);

      flex: 0 0 var(--mdc-checkbox-checkbox-size);
      padding: 0;

      .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
      .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
      .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true'][disabled] ~ .mdc-checkbox__background {
        background-color: @color-primary;
      }

      .mdc-checkbox__ripple {
        visibility: hidden;
      }

      &:hover {
        .mdc-checkbox__ripple {
          visibility: visible;
        }
      }

      &__background {
        width: inherit;
        height: inherit;

        top: 0;
        left: 0;

        border-width: 0.1rem;
      }

      &__native-control {
        top: 0;
        left: 0;
        width: var(--mdc-checkbox-checkbox-size);
        height: var(--mdc-checkbox-checkbox-size);

        // manage ripple on hover (should be visible) and focus (should be hidden except checkbox is hovered as well)
        &:hover ~ .mdc-checkbox__ripple {
          opacity: 0.08;
        }
        &:not(:hover):focus ~ .mdc-checkbox__ripple {
          opacity: 0;
        }
      }
    }

    .mdc-form-field {
      user-select: none;
      cursor: inherit;
      vertical-align: middle;
      display: inline-flex;
      white-space: nowrap;
    }
  }
}
