@import (reference, optional) '../../constants';

:root {
  .mat-mdc-slide-toggle {
    height: 2.4rem;
    line-height: 2.4rem;

    --mdc-switch-track-width: 2.8rem;
    --mdc-switch-handle-width: 1.8rem;
    --mdc-switch-handle-height: 1.8rem;
    --mdc-switch-state-layer-size: 3.2rem;

    --mdc-switch-disabled-selected-handle-color: var(--colorPrimary);
    --mdc-switch-disabled-unselected-handle-color: @color-blue-gray-300;
    --mdc-switch-disabled-selected-track-color: rgba(var(--colorPrimaryRgb), 0.54);
    --mdc-switch-disabled-unselected-track-color: @color-blue-gray-100;
    --mdc-switch-disabled-track-opacity: 0.38;

    --mdc-switch-unselected-track-color: @color-blue-gray-100;
    --mdc-switch-unselected-focus-track-color: @color-blue-gray-100;
    --mdc-switch-unselected-hover-track-color: @color-blue-gray-100;
    --mdc-switch-unselected-pressed-track-color: @color-blue-gray-100;

    --mdc-switch-unselected-handle-color: @color-blue-gray-300;
    --mdc-switch-unselected-focus-handle-color: @color-blue-gray-300;
    --mdc-switch-unselected-hover-handle-color: @color-blue-gray-300;
    --mdc-switch-unselected-pressed-handle-color: @color-blue-gray-300;

    .mdc-switch__icons {
      display: none;
    }

    .mdc-form-field {
      --mdc-theme-text-primary-on-background: rgb(0, 0, 0);

      &.mdc-form-field--align-end > label {
        padding-right: 0.8rem;
      }

      &:not(.mdc-form-field--align-end) > label {
        padding-left: 0.8rem;
      }
    }

    .mdc-switch:hover .mdc-switch__ripple::after {
      opacity: 0.08;
    }

    // makes ripple invisible when slide toggle is focused but NOT hovered
    &.mat-mdc-slide-toggle-focused:not(:hover) .mdc-switch .mdc-switch__ripple::after {
      opacity: 0;
    }
  }

  .mat-primary,
  .mat-accent,
  .mat-warn {
    &.mat-mdc-slide-toggle {
      --mdc-switch-selected-handle-color: var(--colorPrimary);
      --mdc-switch-selected-focus-handle-color: var(--colorPrimary);
      --mdc-switch-selected-hover-handle-color: var(--colorPrimary);
      --mdc-switch-selected-pressed-handle-color: var(--colorPrimary);

      --mdc-switch-selected-track-color: rgba(var(--colorPrimaryRgb), 0.54);
      --mdc-switch-selected-focus-track-color: rgba(var(--colorPrimaryRgb), 0.54);
      --mdc-switch-selected-hover-track-color: rgba(var(--colorPrimaryRgb), 0.54);
      --mdc-switch-selected-pressed-track-color: rgba(var(--colorPrimaryRgb), 0.54);

      --mdc-switch-selected-focus-state-layer-color: var(--colorPrimary);
      --mdc-switch-selected-hover-state-layer-color: var(--colorPrimary);
      --mdc-switch-selected-pressed-state-layer-color: var(--colorPrimary);
    }
  }
}
