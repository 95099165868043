@import 'constants';
@import 'shared-styles';

.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background-color: rgba($color-blue-gray-800, 0.12);
}

.mat-mdc-option:hover:not(.mdc-list-item--selected) {
  background-color: rgba($color-blue-gray-800, 0.08);
}

.mat-mdc-option {
  min-height: unset !important;
  font-size: inherit;
  line-height: 3.2rem;
  height: 3.2rem;

  .mdc-list-item__primary-text {
    width: 100%;
  }
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  width: 100%;
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.cdk-overlay-pane {
  min-width: fit-content;
}
