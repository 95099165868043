@import 'constants';

mat-slider.mdc-slider.mat-mdc-slider {
  --mdc-slider-active-track-color: #{$color-blue-gray-300};
  --mdc-slider-handle-color: #{$color-blue-gray-300};
  --mdc-slider-focus-handle-color: #{$color-blue-gray-400};
  --mdc-slider-hover-handle-color: #{$color-blue-gray-400};

  .mdc-slider__track {
    height: 0;

    .mdc-slider__track--active,
    .mdc-slider__track--inactive {
      border-radius: 0;
      height: 0.2rem;
    }

    .mdc-slider__track--inactive {
      top: -0.1rem;
    }

    .mdc-slider__track--active {
      background-color: $color-blue-gray-050;
    }
  }

  .mdc-slider__thumb,
  .mat-mdc-slider-visual-thumb,
  .mdc-slider__thumb--focused {
    .mdc-slider__thumb-knob {
      width: 1.2rem;
      height: 1.2rem;
      border-width: 0.6rem;
      box-shadow: $box-shadow-4dp;
    }

    .mdc-slider__value-indicator-container {
      bottom: 3.6rem; // controls distance between slider-track and indicator
      background-color: unset;
      width: 5rem;
      border: 0.1rem;

      .mdc-slider__value-indicator {
        opacity: 1;
        transition: unset;
        transform: unset;
        background-color: unset;
        padding: unset;
        height: unset;
        display: flex;

        &::before {
          content: unset;
        }

        &-text {
          color: $color-blue-gray-900;
          font: $font-body;
          text-align: center;
          flex: 1;
        }
      }
    }

    .mat-ripple {
      display: none; // [disableRipple]="true" does not work
    }
  }
  &:hover {
    --mdc-slider-handle-color: #{$color-blue-gray-400};
  }
}
