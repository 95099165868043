@import 'constants';
@import 'shared-styles';

/* You can add global styles to this file, and also import other style files */
:root {
  --colorPrimaryRgb: 1, 169, 202;
  --colorPrimary: rgb(var(--colorPrimaryRgb));
  color: $color-blue-gray-900;

  // putting it outside won't work
  .magic-button-entry {
    width: auto;
  }

  // design for message dialog changes
  celum-message-dialog {
    .dialog-content_scrollable > .ps > .ps-content {
      padding: 4rem;

      .message_icon {
        padding: 0.8rem 0 1.6rem;
      }

      .message_title {
        margin-bottom: 3.2rem;
      }
    }
  }

  // design for context menu changes from common-components
  button.mat-mdc-menu-item {
    font: $font-body !important;
    padding: 0 1.6rem;

    .icon-menu-item_name {
      margin-left: 2.4rem;
      line-height: 1.8rem;
    }
  }

  .mat-mdc-button-base.mdc-button.celum-btn .celum-icon {
    margin: 0 0.65rem 0 0;
  }

  .celum-input-form-field--medium .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
    margin-left: -0.3rem;
  }

  .mat-mdc-form-field.celum-input-form-field {
    margin-top: 0;
  }

  .mat-mdc-form-field-infix {
    min-height: auto;
  }

  .mat-mdc-tooltip {
    border-radius: 4px;
    /* stylelint-disable-next-line color-no-hex */
    background-color: #334e68; // no variable as we want to use portal theme color for experience + portals

    .mdc-tooltip__surface {
      /* stylelint-disable-next-line color-no-hex */
      background-color: #334e68; // no variable as we want to use portal theme color for experience + portals
    }
  }

  // added this because the injection token (setting the disableTooltipInteractivity to true) is not working if the tooltip trigger is inside an overlay
  .mat-mdc-tooltip-panel {
    pointer-events: none;
  }

  .mat-mdc-menu-panel {
    box-shadow:
      0 0.2rem 0.4rem -0.1rem rgba(38 50 56 / 0.2),
      0 0.4rem 0.5rem 0 rgba(38 50 56 / 0.14),
      0 0.1rem 1rem 0 rgba(38 50 56 / 0.12);
    min-width: 14.3rem;
  }

  .mat-mdc-menu-content > hr {
    margin: 0.8rem 1.6rem;
  }
}

.exp_snackbar-list {
  position: fixed;
  z-index: 10000000;

  width: 50rem;
  bottom: 2rem;
  transition: bottom 0.2s ease 0s;
  left: 8rem;

  display: unset !important;
  filter: none !important;
}

.containment-context {
  container-type: inline-size;
}

// in the designer, snackbar should be positioned higher except when full window dialog is opened over the designer
body:has(exp-designer):not(:has(.celum-wizard, por-detail-view, .magic-button-page_container)) .exp_snackbar-list {
  bottom: 8rem;
}

.mat-mdc-chip-action-label {
  @include chip;
}

.por-dialog .mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: 0.8rem;
}

// fix for doubled tooltips in safari
[spaceAwareTooltip]::after {
  content: '';
  display: block;
}
