/* stylelint-disable */
@import 'style-util';

$color-primary: var(--color-primary);
$color-contrast: var(--color-contrast);

$color-gray-1: var(--color-gray-1);
$color-gray-2: var(--color-gray-2);
$color-gray-3: var(--color-gray-3);
$color-gray-4: var(--color-gray-4);
$color-gray-5: var(--color-gray-5);
$color-gray-6: var(--color-gray-6);

$color-white: var(--color-white);
$color-black: $color-gray-6;
$color-error: var(--color-error);
$color-warning: var(--color-warning);
$color-info: var(--color-info);
$color-success: var(--color-success);

// todo legacy colors, remove once migration is done https://celum.atlassian.net/browse/SPOR-1242
$color-global-background: var(--color-global-background);
$color-global-foreground: var(--color-global-foreground);

$font-family: var(--dt-typ-main-family, var(--font-family));
$font-display: var(--font-display);
$font-headline: var(--font-headline);
$font-title: var(--font-title);
$font-subheader: var(--font-subheader);
$font-body: var(--font-body);
$font-caption: var(--font-caption);
$font-button: var(--font-button);

$mobile-elements-height: 4rem;

$font-transparent-middle: 0.76;
$font-transparent-small: 0.56;
$font-transparent-disabled: 0.36;

$box-shadow-1:
  0 1px 8px 0 changeOpacity($color-gray-6, 0.04),
  0 3px 7px -4px changeOpacity($color-gray-6, 0.04),
  0 2px 9px 0 changeOpacity($color-gray-6, 0.05);
$box-shadow-2:
  0 2px 4px -1px changeOpacity($color-gray-6, 0.02),
  0 1px 10px 0 changeOpacity($color-gray-6, 0.12),
  0 4px 5px 0 changeOpacity($color-gray-6, 0.14);

$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;
$media-query-mobile: '(max-width: ' ($breakpoint-mobile) ')';
$media-query-tablet: '(min-width: ' ($breakpoint-mobile + 1) ') and (max-width: ' $breakpoint-tablet ')';
$media-query-desktop: '(min-width: ' ($breakpoint-tablet) ')';
$media-query-mobile-and-tablet: '(max-width: ' ($breakpoint-tablet) ')';
$media-query-tablet-and-desktop: '(min-width: ' ($breakpoint-mobile + 1) ')';
