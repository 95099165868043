:root {
  .mat-mdc-radio-button.mat-primary {
    --mdc-radio-selected-focus-icon-color: var(--colorPrimary);
    --mdc-radio-selected-hover-icon-color: var(--colorPrimary);
    --mdc-radio-selected-icon-color: var(--colorPrimary);
    --mdc-radio-selected-pressed-icon-color: var(--colorPrimary);
    --mat-radio-checked-ripple-color: transparent;
  }
}

.mdc-radio__outer-circle,
.mdc-radio__inner-circle,
.mdc-radio__background,
.mdc-radio {
  height: 14px !important; // we want use smaller radio buttons
  width: 14px !important;
}

.mdc-radio__inner-circle {
  border-width: 7px !important;
}
