.mdc-data-table__cell,
mat-header-cell.mdc-data-table__header-cell,
mat-header-cell.mdc-data-table__header-cell:first-of-type,
mat-header-cell.mdc-data-table__header-cell:last-of-type {
  padding: 0;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border: inherit;
}
